import React from "react"

import Layout from "../../components/layout-static"
import Seo from "../../components/seo"


const Page = () => {
  const metadata = {
      title: 'Videos',
      heroH1: 'BARTLETT LEGAL GROUP VIDEOS',
      heroH2: 'Curated videos of our firm in action',
  }
  return (    
    <Layout metadata={metadata}>
      <Seo title={metadata.title} />
      <div id="content" className="flex flex-wrap container mx-auto px-4 xl:px-0">
          <div className="w-full pt-8 xl:pt-0">
              <div className="mb-16 text-center" id="testimonial-fall">
                  <h2 className="mb-8">Man injured after falling through stairway</h2>
                  <iframe className="mx-auto max-w-full" width="480" height="270" src="https://www.youtube.com/embed/4vlW87AxauE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="mb-16 text-center">
                  <h2>Motorcycle client’s review</h2>
                  <iframe className="mx-auto max-w-full" width="480" height="270" src="https://www.youtube.com/embed/Nbv1zj1YXpk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="mb-16 text-center" id="video-appeals">
                  <h2>CT Supreme Court Dram Shop arguments</h2>
                  <p className="mb-4">Below are Attorney Bartlett’s arguments before the CT Supreme Court in 2013.  What qualifies a person as an expert witness in court? Over the past  approximately seven years since this video was recorded it has been viewed over 12,300 times by attorneys, students, and potential clients. It is one of the most viewed videos in the CTN archives.</p>
                  <iframe className="mx-auto max-w-full" width="480" height="270" src="https://www.youtube.com/embed/h0CkeoYruaU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
          </div>
      </div>
    </Layout>
  )
}


export default Page
